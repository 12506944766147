.documents {
  display: grid;
  max-width: 1440px;
  grid-template-areas: 'menu content';
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
  grid-gap: 1em;
  margin: 0 auto;
  padding: 0;
}

.documents__side {
  grid-area: menu;
}

.documents__content {
  grid-area: content;
  padding: 1em;
  font-size: calc( var(--text-font-size) - 6px );
}

.documents__section-content {
  border-radius: 15px;
  overflow: auto;
  background: var(--menu-background-color);
  padding: 1em;
}

.documents__table {
  width: 100%;
  border-collapse: collapse;
}

.documents__table tr:not(:first-child) {
  border-top: 1px solid #e8edff;
}

.documents__table td:nth-child(1) {
  color: var(--text-color-blue);
}

.documents__table tr:hover td {
  background: var(--table-hover-color);
}

.table-td {
  padding: 10px 15px;
}

@media screen and (max-width: 468px) {
  .table-td:first-child {
    padding: 8px;
  }
}

.documents__worktime {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  font-size: calc( var(--text-font-size) - 4px );

  border-radius: 15px;
  padding: 1em;

  background: linear-gradient(to right, rgba(201, 255, 119, 0.67), rgba(149, 227, 255, 0.71));
}

.documents__worktime h4 {
  padding: 0;
  margin: 0;
}

.documents__worktime table {
  width: 75%;
  border-collapse: collapse;
  text-align: center;
}


.documents__title {
  font-size: var(--title-font-size);
  padding-left: 12px;
}

.documents__site {
  text-decoration: none;
  color: var(--text-color-blue);
}