.accessibility__tools {

}

.accessibility__example {
  color: var(--text-color);
  font-size: var(--text-font-size);
}

.accessibility__percent {
  display: block;
  width: 60px;
  height: 60px;

  color: whitesmoke;
  background-color: var(--text-color-orange);

  border-radius: 50%;
  font-size: 18px;
  font-weight: 500;

  align-content: center;
  text-align: center;
}

.accessibility__button {
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 1em;
  border-radius: 16px;

  cursor: pointer;

  height: 40px;
}

.accessibility__button:hover, .accessibility__button:focus {
  background-color: lightgrey;
}

.accessibility__image {
  width: 122px;
  opacity: 1;

  transition: opacity .5s ease-in-out;
}

.accessibility__image.hidden {
  opacity: 0;
}

.accessibility__row {
  display: flex;
  gap: 1em;
}

.accessibility__cell {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 234px;
}

.icon__flip {
  transform: scaleX(-1);
  transition: transform .4s ease-in-out;
}