.contacts{
  width: calc(100% - 86px);
  max-width: 1440px;
  margin: 0 auto;
  padding: 4em 0 0;
}

@media screen and (max-width: 890px) {
  .contacts{
    width: calc(100% - 42px);
  }
}


.contacts__content{
  width: 100%;
  height: auto;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;

  background-color: var(--form-background-color);
  border-radius: 24px;
  padding: 2em;
  margin-top: 8px;

  box-sizing: border-box;
}

@media screen and (max-width: 768px) {
  .contacts__content{
    padding: 1em;

    display: flex;
    flex-direction: column;

    height: auto;
  }
}

.contacts__personal-info {
  font-size: calc(var(--text-font-size) - 8px);
  color: var(--contact-font-color);
  margin: 8px 0;
}