.header__block{
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 48px 1fr;
  grid-template-rows: 1fr 1fr;
  text-wrap: nowrap;
}

@media screen and (max-width: 1360px) {
  .header__block{
    grid-gap: 8px;
    grid-template-columns: 42px 1fr;
  }
}

@media screen and (max-width: 1024px) {
  .header__block{
    grid-gap: 6px;
    grid-template-columns: 48px 1fr;
  }
}