.contacts__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap:1em;
}

.contacts__info-box {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.contacts__info-label {
  color: var(--contact-font-color);
  font-size: calc(var(--text-font-size) - 6px);
  font-weight: 500;
  line-height: 2;
}

.contacts__info-link {
  color: var(--text-color-green);
  text-decoration: none;
}

.contacts__info-subtitle {
  font-size: smaller;
}

.contacts__info-title,
.contacts__info-link {
  font-size: var(--title-font-size);
  font-weight: 500;
}

.contacts__info-socials {
  display: flex;
  gap: 1em;
}

@media screen and (max-width: 686px) {
  .contacts__info-socials {
    max-width: 168px;
  }
}

@media screen and (max-width: 570px) {
  .contacts__info-socials {
    max-width: 200px;
  }
}

.contacts__info-socials-link {
  color: var(--text-color);
  font-weight: 500;
  font-size: calc(var(--title-font-size) - 2px);
  text-decoration: none;
}

.contacts__info-socials-link:hover {
  color: var(--text-color-orange);
}

@media screen and (max-width: 686px) {
  .contacts__info-socials-link {
    flex: 1 1 40%;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 570px) {
  .contacts__info-socials-link {
    text-align: center;
  }
}

.contacts__info-title_orange {
  color: var(--text-color-orange);
}


@media screen and (max-width: 768px) {
  .contacts__info-box{
    align-items: flex-start;
  }

  .contacts__info{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

}

@media screen and (max-width: 570px) {
  .contacts__info-box {
    align-items: center;
  }

  .contacts__info {
    display: flex;
    flex-direction: column;
  }
}
