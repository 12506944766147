.menu__mobile{
  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;

  background-color: var(--button-background-color);
  border: none;
  outline: none;

  visibility: hidden;
  cursor: pointer;

  padding: 20px;
  z-index: 4;
}

@media screen and (max-width: 1024px) {
  .menu__mobile{
    visibility: visible;
  }
}

.menu__mobile span{
  position: absolute;

  width: 30px;
  height: 3px;

  background-color: white;
  transition: background-color .5s, opacity .25s, transform .5s;
  will-change: transform;
}

.menu__mobile span:nth-child(1){
  transform: translateY(-10px);
}

.menu__mobile span:nth-child(2){}

.menu__mobile span:nth-child(3){
  transform: translateY(10px);
}

.menu .menu__mobile,
.menu.menu__open .menu__mobile {
  transition: background-color .2s ease-in-out;
}

.menu .menu__mobile:hover,
.menu.menu__open .menu__mobile:hover {
  background-color: var(--background-color-orange);
}

.menu.menu__open .menu__mobile span {
  background-color: var(--menu-line-color);
}

.menu.menu__open .menu__mobile {
  background-color: var(--menu-button-background-color);
}

.menu.menu__open .menu__mobile span:nth-child(1){
  transform: translateY(0) rotate(45deg);
}

.menu.menu__open .menu__mobile span:nth-child(2){
  opacity: 0;
}

.menu.menu__open .menu__mobile span:nth-child(3){
  transform: translateY(0) rotate(-45deg);
}

.menu__background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 0;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: visibility 400ms, opacity 300ms linear;
}

.menu__background_opened {
  visibility: visible;
  opacity: 1;
}