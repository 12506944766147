.card {
  position: relative;

  -ms-flex-preferred-size: 264px;
  flex-basis: 264px;
  min-height: 200px;
  overflow: hidden;
  border-radius: 28px;

  background-color: var(--card-background-color);
  cursor: pointer;
}

@media screen and (max-width: 1080px) {
  .card{
    -ms-flex-preferred-size: 208px;
    flex-basis: 208px;
    min-height: 50px;
  }
}


.card__tag {
  height: 128px;
  width: 128px;
  background-color: #7fd1d2;

  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;

  border-radius: 50%;

  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}

.card:nth-child(2n) .card__tag {
  background-color: #7fd280;
}
.card:nth-child(3n) .card__tag {
  background-color: #d2807f;
}
.card:nth-child(4n) .card__tag {
  background-color: #807fd2
}
.card:nth-child(5n) .card__tag {
  background-color: #f9b234;
}
.card:nth-child(6n) .card__tag {
  background-color: #4c49ea;
}


.card__link{
  display: flex;
  flex-direction: column;
  gap: 1em;

  text-decoration: none;

  padding: 20px 18px;
}

.card__link:hover,
.card__link:hover .card__title,
.card__link:hover .card__price,
.card__link:hover .card__subtitle {
  color: #FFF;
}

.card__link:hover .card__title{
  text-decoration: underline;
}

.card__link:hover .card__content{
  bottom: 0;
}

.card__link:hover .card__footer{
  visibility: visible;
}

.card__link:hover .card__tag {
  -ms-transform: scale(10);
  transform: scale(10);
}

.card__title{
  position: relative;
  font-weight: 600;

  overflow: hidden;

  font-size: 20px;

  z-index: 2;
}

@media screen and (max-width: 425px) {
  .card__title{
    font-size: 20px;
  }
}


.card__subtitle{
  min-height: 64px;

  font-size: 14px;
  font-style: italic;

  z-index: 2;
}


.card__price{
  width: 95%;

  font-size: 20px;
  font-weight: bold;

  color: #f64f4f;
  text-align: end;

  -webkit-transition: color .5s ease;
  -o-transition: color .5s ease;
  transition: color .5s ease;

  white-space: nowrap;
  z-index: 2;
}

.card__price:after{
  content: '₽';
}

@media screen and (max-width: 768px) {
  .card__price{
    font-size: 18px;
  }
}

@media screen and (max-width: 425px) {
  .card__price{
    font-size: 18px;
  }
}


.card__content{
  position: absolute;
  bottom: -24px;
  left: 0;

  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 100%;

  transition: bottom .1s ease-in-out;
  z-index: 2;
}

.card__footer{
  height: 24px;

  padding: 8px;
  text-align: center;
  background-color: rgba(43, 48, 35, 0.49);

  z-index: 2;
  visibility: hidden;
}