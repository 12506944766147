.categories {
  display: flex;
  flex-direction: column;
  width: calc(100% - 86px);
  max-width: 1440px;
  margin: 0 auto;
  padding: 4em 0 0;
}

@media screen and (max-width: 890px) {
  .categories {
    width: calc(100% - 42px);
  }
}


.categories_cards{
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  justify-content: space-evenly;

  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  gap: 1em;

  margin: 0 auto;
}

@media screen and (max-width: 1480px) {
  .categories_cards {
    max-width: 1064px;
  }
}

@media screen and (max-width: 1080px) {
  .categories_cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1em;
  }
}

@media screen and (max-width: 472px) {
  .categories_cards {
    grid-template-columns: 1fr;
  }
}