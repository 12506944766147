.header__social {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: 86px;
  background-color: var(--icon-background-color);
  border-radius: 8px;
  padding: 6px;

  box-sizing: border-box;
}

@media screen and (min-width: 1440px) {
  .header__social{
    width: fit-content;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1440px) {
  .header__social {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Два столбика */
    grid-gap: 8px; /* Расстояние между элементами */
    max-width: 1360px; /* Максимальная ширина контейнера */
    margin: 0 auto; /* Центрирование */
  }
}

@media screen and (max-width: 1280px) {
  .header__social{
    width: 250px;
    justify-content: center;
  }
}

@media screen and (max-width: 890px) {
  .header__social{
    width: 100%;
    grid-column: 1 / 2;
    grid-row: 3 / 3;
  }
}

.header__social_icon {
  width: 32px;
  height: 32px;
  transition: width, height 150ms ease-in-out;
}

.header__social_icon:hover{
  width: 35px;
  height: 35px;
}

@media screen and (max-width: 1360px) {
  .header__social_icon {
    width: 32px;
  }
}