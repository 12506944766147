.header {
  display: grid;
  grid-gap: 1em;

  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;

  grid-template-rows: 1fr;

  font-size: calc(var(--header-font-size) - 1px);

  max-width: 1440px;
  width: calc(100% - 86px);

  align-items: center;
  justify-content: space-between;

  margin: 0 auto;
  padding: 2em 1em 1em;
  box-sizing: border-box;
}

@media screen and (max-width: 1460px) {
  .header{
    font-size: 16px;
  }
}

@media screen and (max-width: 1280px) {
  .header{
    width: fit-content;
    grid-template-columns: 2fr repeat(2, 1fr);
  }
}

@media screen and (max-width: 890px) {
  .header {
    width: fit-content;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
  }
}

@media screen and (max-width: 544px) {
  .header{
    grid-template-columns: 1fr;
    width: fit-content;
    padding: 2em 1em 1em;
  }
}