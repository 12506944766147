.check-container {
  width: 6.25rem;
  height: 7.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
}

.check-background {
  width: 100%;
  height: calc(100% - 1.25rem);
  background: linear-gradient(to bottom right, #27b7ff, var(--icon-background-color));
  box-shadow: 0 0 0 65px rgba(255, 255, 255, 0.25) inset,
  0 0 0 65px rgba(255, 255, 255, 0.25) inset;
  transform: scale(0.84);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.check-background svg {
  width: 65%;
  transform: translateY(0.25rem);
  stroke-dasharray: 80;
  stroke-dashoffset: 80;
}

/* Тень */
.check-shadow {
  bottom: calc(-15% - 5px);
  left: 0;
  border-radius: 50%;
  background: radial-gradient(closest-side, var(--icon-background-color), transparent);
  opacity: 0;
}

/* Анимации при добавлении класса 'animate' */
.animate .check-background {
  animation: animateContainer 0.75s ease-out forwards .2s;
}

.animate .check-background svg {
  animation: animateCheck 0.35s forwards .5s ease-out;
}

.animate .check-shadow {
  animation: animateShadow 0.75s ease-out forwards 0.75s;
}

@keyframes animateContainer {
  0% {
    opacity: 0;
    transform: scale(0);
    box-shadow: 0 0 0 65px rgba(255, 255, 255, 0.25) inset,
    0 0 0 65px rgba(255, 255, 255, 0.25) inset;
  }
  25% {
    opacity: 1;
    transform: scale(0.9);
    box-shadow: 0 0 0 65px rgba(255, 255, 255, 0.25) inset,
    0 0 0 65px rgba(255, 255, 255, 0.25) inset;
  }
  43.75% {
    transform: scale(1.15);
    box-shadow: 0 0 0 43.334px rgba(255, 255, 255, 0.25) inset,
    0 0 0 65px rgba(255, 255, 255, 0.25) inset;
  }
  62.5% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.25) inset,
    0 0 0 21.667px rgba(255, 255, 255, 0.25) inset;
  }
  81.25% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.25) inset,
    0 0 0 0 rgba(255, 255, 255, 0.25) inset;
  }
  100% {
    opacity: 1;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.25) inset,
    0 0 0 0 rgba(255, 255, 255, 0.25) inset;
  }
}

@keyframes animateCheck {
  from {
    stroke-dashoffset: 80;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes animateShadow {
  0% {
    opacity: 0;
    width: 100%;
    height: 15%;
  }
  25% {
    opacity: 0.25;
  }
  43.75% {
    width: 40%;
    height: 7%;
    opacity: 0.35;
  }
  100% {
    width: 85%;
    height: 15%;
    opacity: 0.25;
  }
}
