.app {
  color: var(--text-color);
  background-color: var(--background-color);
  font-family: 'Inter', 'Arial', sans-serif;

  width: 100%;

  min-height: 100vh;

  text-rendering: optimizeLegibility;
  -moz-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;

  transition: background-color .5s ease-in-out;
}