.documents__menu {
  display: flex;
  flex-direction: column;
  gap: 1em;


  background: var(--menu-background-color);

  border-radius: 1em;
  padding: 1em;

  text-transform: uppercase;
}

.documents__menu-item {
  list-style-type: none;

  font-size: var(--menu-font-size);

  text-align: left;

  cursor: pointer;
}

.documents__menu-link {
  color:var(--menu-font-color);

  text-decoration: none;
  font-weight: 500;
  transition: color 150ms ease-in-out;
}

.documents__menu-item:hover .documents__menu-link{
  color: var(--text-color-orange);
}

.documents__menu-link_selected {
  color: var(--menu-selected-color);
}