.contacts__form{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;
}

.contacts__input {
  width: 100%;
  height: 36px;
  color: var(--form-input-font-color);
  background-color: var(--form-input-background-color);

  font-style: normal;
  font-weight: 400;
  font-size: calc(var(--text-font-size) - 2px);
  line-height: 24px;

  border: 0;
  border-bottom: 1px solid rgba(0,0,0,.2);
  border-radius: 16px;

  margin: 0;
  padding: 1em 1em;

  outline:none;
  box-sizing: border-box;
}

textarea.contacts__input {
  min-height: 108px;
}

.contacts__input:focus {
  border-bottom-color: var(--text-color);
}

.contacts__input_error {
  border-bottom-color: var(--text-color-orange);
}

.contacts__input_valid {
  border-bottom-color: var(--text-color-green);
}