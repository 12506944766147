.footer{
  color: var(--text-color);
  padding: 1em;
  height: auto;
  background: var(--footer-background-color);

  text-align: center;
}

.footer__content{
  font-size: calc(var(--footer-font-size) - 2px);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  justify-items: center;
  grid-gap: 10px;
  width: calc(100% - 86px);
  margin: 0 auto;
}


.footer__block{
  display: flex;
  flex-direction: column;
  gap: 1em;
  text-align: left;
}

.footer__link{
  color: var(--footer-text-color);
  text-decoration: none;
}

.footer__link:hover{
  color: var(--background-color-orange);
}

.footer__copyright{
  font-size: var(--footer-font-size);
  color: var(--footer-text-color);
  grid-column: 1 / 4;
  grid-row: 2 / 3; /* Занимает весь второй ряд */
}

@media screen and (max-width: 468px) {
  .footer__block{
    width: 250px;
    gap: 8px;
  }

  .footer__content{
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    grid-gap: 2em;

    width: unset;
  }

  .footer__copyright {
    grid-column: unset;
    grid-row: unset;
  }
}