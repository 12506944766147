.header__link {
  color: var(--header-font-color);
  font-weight: 500;
  text-decoration: none;
}

.header__link:hover {
  color: var(--text-color-orange);
}

.header__link_orange {
  color: var(--text-color-orange);
  font-weight: 600;
}

.header__link_green {
  color: var(--text-color-green);
  font-weight: 500;
}