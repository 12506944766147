.header__logo {
  display: grid;

  grid-row-start: 1;
  grid-column-start: 1;

  grid-row-end: 3;
  grid-column-end: 2;
}

@media screen and (max-width: 544px) {
  .header__logo {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.logo {
  width: 100%;
}

@media screen and (max-width: 1280px) {
  .header__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .logo {
    width: 70%;
  };
}

@media screen and (max-width: 1024px) {
  .logo{
    width: 80%;
  }
}