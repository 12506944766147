.header__icon {
  width: 32px;
  height: 32px;
  background-color: var(--icon-background-color);
  border-radius: 8px;
  padding: 8px;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 32px, auto, cover;
  transition: background-color,background-size 150ms ease-in-out;

  grid-column: 1/2;
  grid-row: 1/3;
}

/*.header__icon:hover{*/
/*  background-color: #cd5c5c;*/
/*  background-size: 36px;*/
/*}*/

@media screen and (max-width: 1360px) {
  .header__icon {
    background-size: 32px, auto, cover;
    width: 24px;
    height: 24px;
  }

  .header__icon:hover{
    background-size: 32px;
  }
}