.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--menu-background-color);
  width: 100%;
  height: 48px;

  gap: 1em;
  margin: 0;
  padding: 8px 1em;

  -webkit-box-shadow: 0 4px 6px -6px #222;
  -moz-box-shadow: 0 4px 6px -6px #222;
  box-shadow: 0 4px 6px -6px #222;

  position: sticky;
  top: 0;

  z-index: 3;
  box-sizing: border-box;
}


@media screen and (max-width: 1024px) {
  .menu{
   justify-content: space-between;
  }
}

.menu__list{
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--menu-background-color);

  gap: 1em;
  margin: 0;
  padding: 0;

  height: 100%;
}

@media screen and (max-width: 1024px) {
  .menu__list{
    position: absolute;
    left: -470px;

    visibility: hidden;
    transition: left .5s ease, visibility .1s;
  }
}

.menu.menu__open .menu__list{
  flex-direction: column;

  width: auto;
  height: 100vh;

  position: absolute;
  top: 0;
  left: 0;

  margin: 0;
  padding: 64px 1em;

  justify-content: unset;
  align-items: flex-start;

  visibility: visible;
}

.menu__item{
  list-style-type: none;

  font-size: var(--header-font-size);
  font-weight: bold;
  line-height: 20px;

  text-align: left;

  cursor: pointer;
}

.menu__link{
  color: var(--header-font-color);
  text-decoration: none;
  transition: color 150ms ease-in-out;
}

.menu.menu__open .menu__link {
  color: var(--text-color);
  font-weight: 500;
}

.menu__item:hover .menu__link{
  color: var(--text-color-orange);
  border-style: solid;
  border-width: 0 0 1px;
}