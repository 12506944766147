.modal__form {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: var(--text-color);
  background-color: var(--background-color);

  width: 430px;
  height: auto;

  margin: 0;
  padding: 24px 24px;
  border-radius: 10px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);

  overflow: hidden;

  z-index: 6;
  box-sizing: border-box;
}

@media only screen and (max-width: 425px) {
  .modal__form {
    width: 100%;
    padding: 18px 18px;
  }
}

.form__header {
  display: flex;
  flex-direction: column;
  gap: .5em;
  margin: 0;
  padding-bottom: 8px;
  border-bottom: 1px solid lightgrey;
  z-index: 1;
}

.form__title {
  color: var(--text-color);
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 425px) {
  .form__title {
    font-size: 18px;
  }
}

.form__subtitle {
  font-weight: 500;
  color: grey;
  font-size: small;
}

.form__info {
  padding: 8px 0;
}

.form__price {
  font-size: 18px;
  color: #f64f4f;
  font-weight: 600;
  text-align: center;
  place-self: center;
}

@media screen and (max-width: 425px) {
  .form__price{
    font-size: 16px;
  }
}

.form__input-error {
  font-size: 12px;
  color: var(--text-color-orange);
}

.form__submit{
  font-size: var(--button-font-size);
  font-weight: 500;

  padding: 0 18px;
  width: fit-content;
  height: 48px;

  color: white;
  background-color: var(--button-background-color);

  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color .2s ease-in-out;
}

.form__submit:hover{
  background-color: var(--background-color-orange);
}

.form__submit_less {
  height: 42px;
}

@media screen and (max-width: 425px) {
  .form__submit_wide {
    width: 100%;
  }
}

.form__footer {
  display: flex;
  justify-content: space-between;

  padding-top: 1em;
  min-height: 14px;
  border-top: 1px solid lightgrey;
}

.form__icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form__tag {
  height: 128px;
  width: 128px;
  background-color: #7fd1d2;

  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;

  border-radius: 50%;
}