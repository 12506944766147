.main {
  display: flex;
  flex-direction: column;
}

.main__title {
  width: fit-content;
  font-size: var(--main-title-font-size);
  margin: 0;
  padding: 0;
}

.main__title_sticky {
  font-size: var(--header-font-size);
  position: sticky;
  top: 12px;
  z-index: 3;
}

@media screen and (max-width: 1024px) {
  .main__title_sticky {
    top: 14px;
    left: 4em;
  }
}

.main__subtitle {
  font-size: var(--text-font-size);
  font-weight: 500;
  padding: 0;
  margin: 1em 0;
}