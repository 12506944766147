.spinner{
  display: flex;
  justify-content: center;
  width: 100%;
}


.spinner__content{
  width: 100%;
  max-width: 1440px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  gap: 1.2em;

  padding: 64px 48px;
  margin: 0;
  box-sizing: border-box;
}

@media screen and (max-width: 1440px) {
  .spinner__content{
    padding: 64px 0 48px 42px;
  }
}

@media screen and (max-width: 544px) {
  .spinner__content{
    padding: 8px;
    gap: 8px;
  }
}


.content__title{
  font-size: var(--spinner-title-font-size);
  line-height: 64px;
  font-weight: 900;

  text-shadow: 2px 2px 4px rgba(31, 45, 31, 0.9);
  padding: 0;
  margin: 8px 0;
}

@media screen and (max-width: 1440px) {
  .content__title{
    line-height: 48px;
    font-weight: 900;
  }
}

@media screen and (max-width: 1024px) {
  .content__title{
    line-height: 42px;
    font-weight: 900;
  }
}

.content__subtitle{
  font-size: var(--main-sub-title-font-size);
  line-height: 52px;
  font-weight: 500;
  letter-spacing: 6px;

  color: #97db97;
  text-shadow: 2px 2px 4px rgba(31, 45, 31, 0.9);

  height: fit-content;
  padding: 0;
  margin: 0;

  white-space: pre-wrap;
  overflow: hidden;
}

@media screen and (max-width: 1440px) {
  .content__subtitle{
    line-height: 48px;
    font-weight: 500;
    letter-spacing: 3px;
  }
}

@media screen and (max-width: 1024px) {
  .content__subtitle{
    line-height: 42px;
    font-weight: 500;
    letter-spacing: 2px;
  }
}

@media screen and (max-width: 544px) {
  .content__subtitle{
    line-height: 36px;
    letter-spacing: 1px;
  }
}


.content__comments{
  display: flex;
  flex-direction: column;
  width: calc(100% - 78px);
  gap: 2em;

  padding: 0;
  margin: 1em 0;
}

@media screen and (max-width: 544px) {
  .content__comments{
    width: 100%;
    gap: 1em;
  }
}


.content__text {
  font-size: var(--text-font-size);
  font-weight: 500;
  line-height: 42px;
  max-width: 1024px;
  text-shadow: 2px 2px 4px rgba(31, 45, 31, 0.9);
}

@media screen and (max-width: 1440px) {
  .content__text {
    max-width: 100%;
  }
}

.content__highlight {
  display: flex;
  gap: 1em;
  background: #33333361;
  font-size: 28px;
  color: #f64f4f;
  border-radius: 24px;
  width: fit-content;
  padding: 10px 18px;
  box-sizing: border-box;
}

.content__highlight span{
  text-align: center;
}

.content__highlight span:last-child{
  grid-column: 1 / span 2;
}

@media screen and (max-width: 520px) {
  .content__highlight span{
    font-size: 24px;
  }

  .content__comments div.content__highlight:nth-child(2) span{
    font-size: 20px;
  }
}

@media screen and (max-width: 380px) {
  .content__highlight span{
    font-size: 20px;
  }

  .content__comments div.content__highlight:nth-child(2) span{
    font-size: 18px;
  }
}

@media screen and (max-width: 356px) {
  .content__comments div.content__highlight:nth-child(2) span{
    font-size: 16px;
  }
}

@media screen and (max-width: 425px) {
  .content__highlight{
    gap: 8px;
    justify-content: space-between;
    width: 100%;
  }
}

@media screen and (max-width: 1280px) {
  .content__comments div.content__highlight:nth-child(2){
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
  }
}

.content__button{
  font-size: var(--button-font-size);
  font-weight: 600;

  padding: 0 18px;
  width: fit-content;
  height: 64px;

  color: white;
  background-color: #699269;

  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: background-color .2s ease-in-out;
}

.content__button:hover{
  background-color: var(--background-color-orange);
}

@media screen and (max-width: 1024px) {
  .content__button{
    height: 48px;
  }
}

@media screen and (max-width: 425px) {
  .content__button{
    width: 100%;
  }
}
