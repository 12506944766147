.accessibility {
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--header-font-color);
  background-color: var(--header-background-color);

  font-size: var(--header-font-size);
  font-weight: 600;

  gap: 8px;
  height: 36px;

  margin: 0;
  padding-right: 8px;

  border: 0;
  outline: 0;

  border-radius: 8px;

  cursor: pointer;

  transition: background-color .3s ease-in-out;
}

.accessibility:hover {
  color: white;
  background-color: var(--background-color-orange);
}

.accessibility:focus {
  color: whitesmoke;
  background-color: #699269;
}

.accessibility:focus .accessibility__icon {
  opacity: 1;
}

.accessibility__icon {
  background-image: url("../../images/header/magnifying_glass.svg");
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;

  margin-top: 8px;
  opacity: .5;

  transition: opacity .4s ease-in-out;
}

.accessibility:hover .accessibility__icon{
  opacity: 1;
}

@media screen and (max-width: 544px) {
  .accessibility__icon {
    width: 1px;
  }
}