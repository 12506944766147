:root {
  /*DEFAULT*/
  --def-text-color: #333333;
  --def-text-color-blue: dodgerblue;
  --def-text-color-green: #699269;
  --def-background-color: #f5f5f5;

  --def-header-font-color: #333333;
  --def-header-background-color: #f5f5f5;

  --def-menu-font-color: #333333;
  --def-menu-button-background-color: #699269;
  --def-menu-line-color: white;

  --def-table-hover-color: #f5f5f5;
  --def-menu-background-color: white;
  --def-card-background-color: white;
  --def-contact-font-color: darkslategrey;

  --def-icon-background-color: #699269;
  --def-button-background-color: #699269;

  --def-form-background-color: white;
  --def-form-input-font-color: #333333;
  --def-form-input-background-color: white;

  --def-footer-text-color: white;
  --def-footer-background-color: #678a67;

  /*Основные*/
  --text-color: var(--def-text-color);
  --dark-text-color: #fff6e0;
  --text-color-orange: #e25111;
  --text-color-blue: var(--def-text-color-blue);
  --dark-text-color-blue: #a2d1ff;
  --text-color-green: var(--def-text-color-green);
  --dark-text-color-green: palegreen;

  /*Фон*/
  --background-color-orange: #e25111;
  --background-color: var(--def-background-color);
  --dark-background-color: #1c1c1a;

  /*Header*/
  --header-font-size: 20px;
  --header-font-color: var(--def-header-font-color);
  --dark-header-font-color: #fff6e0;
  --header-background-color: var(--def-header-background-color);
  --dark-header-background-color: #1c1c1a;

  /*Spinner*/
  --spinner-title-font-size: 58px;
  --button-font-size: 22px;

  /*Main*/
  --main-title-font-size: 42px;
  --title-font-size: 22px;
  --main-sub-title-font-size: 42px;
  --text-font-size: 24px;

  /*Таблицы*/
  --table-hover-color: var(--def-table-hover-color);
  --dark-table-hover-color: darkslategrey;

  /*Меню и формы*/
  --menu-button-background-color: var(--def-menu-button-background-color);
  --dark-menu-button-background-color: #fff6e0;

  --menu-line-color: var(--def-menu-line-color);
  --dark-menu-line-color: #333333;

  --menu-background-color: var(--def-menu-background-color);
  --dark-menu-background-color: #2e3737;

  --menu-font-size: 18px;
  --menu-font-color: var(--def-menu-font-color);
  --menu-selected-color: var(--text-color-blue);
  --dark-menu-font-color: #fff6e0;

  --form-background-color: var(--def-form-background-color);
  --dark-form-background-color: #2e3737;

  /*Иконки кнопки*/
  --icon-background-color: var(--def-icon-background-color);
  --dark-icon-background-color: darkslategrey;
  --button-background-color: var(--def-button-background-color);
  --dark-button-background-color: darkslategrey;

  /*Карточки*/
  --card-background-color: var(--def-card-background-color);
  --dark-card-background-color: darkslategrey;

  /*Контакты*/
  --contact-font-color: var(--def-contact-font-color);
  --dark-contact-font-color: grey;

  /*Поля ввода*/
  --form-input-font-color: var(--def-form-input-font-color);
  --dark-form-input-font-color: white;
  --form-input-background-color: var(--def-form-input-background-color);
  --dark-form-input-background-color: #1c1c1a;

  /*Footer*/
  --footer-font-size: 18px;
  --footer-text-color: var(--def-footer-text-color);
  --dark-footer-text-color: #fff6e0;
  --footer-background-color: var(--def-footer-background-color);
  --dark-footer-background-color: #2e3737;
}


@media screen and (max-width: 1440px) {
  :root {
    --button-font-size: 22px;
    --header-font-size: 18px;
    --spinner-title-font-size: 48px;
    --main-title-font-size: 40px;
    --main-sub-title-font-size: 38px;
    --text-font-size: 20px;
  }
}

@media screen and (max-width: 1024px) {
  :root {
    --main-title-font-size: 38px;
    --spinner-title-font-size: 42px;
    --main-sub-title-font-size: 36px;
    --text-font-size: 18px;
    --footer-font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  :root {
    --main-title-font-size: 32px;
    --button-font-size: 18px;
    --footer-font-size: 15px;
    --title-font-size: 18px;
  }
}

@media screen and (max-width: 544px) {
  :root {
    --header-font-size: 16px;
    --spinner-title-font-size: 32px;
    --main-title-font-size: 28px;
    --main-sub-title-font-size: 22px;
  }
}
