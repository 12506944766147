.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 5;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: visibility 400ms, opacity 300ms linear;
}

.modal_opened {
  visibility: visible;
  opacity: 1;
  transition: visibility 300ms, opacity 400ms linear;
}

.modal__fieldset {
  border: 0;
  border-radius: 0;
  padding: 0;
  margin: 0;
}

.modal__container {
  position: relative;
}

@media only screen and (max-width: 425px) {
  .modal__container {
    width: calc(100% - 38px);
  }
}

.modal__close {
  background: transparent url(../../images/modal/x_close-btn.svg) no-repeat;
  background-size: 32px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: -40px;
  right: -40px;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

@media only screen and (max-width: 690px) {
  .modal__close {
    background-size: 20px;
    width: 20px;
    height: 20px;
    top: -38px;
    right: 0;
  }
}

.modal__captcha {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: .5em;
}